<template>
  <div>
    <q-form ref="editForm">
      <c-card class="cardClassDetailForm" title="LBLDETAIL">
        <template slot="card-button">
          <q-btn-group outline >
            <!-- 삭제 -->
            <c-btn v-if="editable&&isOld" label="LBLREMOVE" icon="delete" @btnClicked="deleteContruction" />
            <!-- 저자  -->
            <c-btn 
              v-if="editable" 
              :isSubmit="isSave"
              :url="saveUrl"
              :param="construction"
              :mappingType="mappingType"
              label="LBLSAVE" 
              icon="save"
              @beforeAction="saveConstruction"
              @btnCallback="saveConstructionCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <!-- 공사명 -->
            <c-text
              :required="true"
              :editable="editable"
              label="LBL0001803"
              name="constructionName"
              v-model="construction.constructionName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 공사기간 -->
            <c-datepicker
              :required="true"
              :editable="editable"
              :range="true"
              label="LBL0001804"
              name="period"
              v-model="construction.period"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 주 공사업체 -->
            <c-vendor
              :editable="editable"
              label="LBL0001807"
              name="vendorCd"
              v-model="construction.vendorCd">
            </c-vendor>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 공사종료여부 -->
            <c-checkbox
              :editable="editable"
              :isFlag="true"
              label="LBL0001811"
              name="constructionFinishFlag"
              v-model="construction.constructionFinishFlag"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 안전보건관리비 -->
            <c-text
              :editable="editable"
              type="number"
              label="LBL0001812"
              name="safetyHealthManageCost"
              v-model="construction.safetyHealthManageCost">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 코스트센터 -->
            <c-text
              :editable="editable"
              label="LBL0001813"
              name="costCenter"
              v-model="construction.costCenter">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 현장 총괄 -->
            <c-text
              :editable="editable"
              label="LBL0001814"
              name="onSiteManager"
              v-model="construction.onSiteManager">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <!-- 안전책임자 -->
            <c-text
              :editable="editable"
              label="LBL0001806"
              name="safetyManager"
              v-model="construction.safetyManager">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
            <!-- MOC번호 -->
            <c-moc 
              :editable="editable"
              :document.sync="construction"
              :documentId="construction.sopConstructionId"
              documentTitle="constructionName"
              label="LBL0000226"
              name="sopMocId"
              v-model="construction.sopMocId"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <!-- 공사내역 -->
            <c-textarea
              :editable="editable"
              :rows="2"
              label="LBL0001815"
              name="constructionDetails"
              v-model="construction.constructionDetails">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </q-form>
      <!-- 공사 참여업체 목록 -->
    <c-table
      ref="vendortable"
      title="LBL0001816"
      :columns="gridVendor.columns"
      :data="construction.vendors"
      :gridHeight="gridVendor.height"
      :editable="editable"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="vendorCd"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 제외 -->
          <c-btn 
            v-if="editable" 
            label="LBLEXCEPT" icon="remove" 
            @btnClicked="deleteVendor" />
          <!-- 추가 -->
          <c-btn 
            v-if="editable" 
            label="LBLADD" icon="add" 
            @btnClicked="addVendor" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'constructionInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopConstructionId: '',
      }),
    },
    construction: {
      type: Object,
      default: () => ({
        sopConstructionId: '',  // 공사 일련번호
        constructionName: '',  // 공사명
        constructionDetails: '',  // 공사내역
        constructionStartDate: '',  // 공사 시작일
        constructionEndDate: '',  // 공사 종료일
        period: [],
        constructionFinishFlag: 'N',  // 공사 종료 여부
        safetyHealthManageCost: 0,  // 안전보건관리비(총 액)
        costCenter: '',  // 코스트센터
        onSiteManager: '',  // 현장 총괄
        safetyManager: '',  // 안전책임자
        vendorCd: '',  // 주 공사업체
        sopMocId: '',  // MOC 일련번호
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        vendors: [], // 참영업체
        deleteVendors: [], // 참영업체 [삭제]
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridVendor: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            // 참여업체
            label: 'LBL0001828',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            fix: true,
          },
          {
            name: 'constructionDetails',
            field: 'constructionDetails',
            // 공사내역
            label: 'LBL0001815',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'textarea',
          },
          {
            name: 'period',
            field: 'period',
            // 참여기간
            label: 'LBL0001817',
            align: 'center',
            style: 'width:200px',
            sortable: false,
            type: 'date',
            range: true,
          },
          {
            name: 'attach1',
            field: 'attach1',
            // 안전관리계획서
            label: 'LBL0001818',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_SAFETY_MANAGE_PLAN',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach2',
            field: 'attach2',
            // 공사(작업)위험성평가
            label: 'LBL0001819',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_RISK_ASSESS',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach3',
            field: 'attach3',
            // 작업계획서
            label: 'LBL0000303',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_WORK_PLAN',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach4',
            field: 'attach4',
            // 교육결과
            label: 'LBL0001820',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_EDU_CERTI',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach5',
            field: 'attach5',
            // 산업안전보건관리비 계획
            label: 'LBL0001821',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_MANAGE_COST_PLAN',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach6',
            field: 'attach6',
            // 보험 증명원
            label: 'LBL0001822',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_INSURANCE_CERTI',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach7',
            field: 'attach7',
            // 검진결과
            label: 'LBL0001823',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_HEA_CHECK',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach8',
            field: 'attach8',
            // 안전검사증
            label: 'LBL0001824',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_SAFETY_INSP_CERTI',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach9',
            field: 'attach9',
            // 설비제원표
            label: 'LBL0001825',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_EQUIP_SPEC_TABLE',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach10',
            field: 'attach10',
            // 자격증명서<br/>(지게차, 크레인 등)
            label: 'LBL0001826',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_CREDENTIALS',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
          {
            name: 'attach11',
            field: 'attach11',
            // 기타
            label: 'LBL0001222',
            align: 'center',
            style: 'width:150px',
            type: 'attach',
            taskClassCd: 'SCM_CONSTRUNCTION_ETC',
            keyText: 'sopConstructionRelationVendorId',
            sortable: false,
          },
        ],
        height: '500px',
      },
      editable: true,
      mappingType: 'PUT',
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return String(this.contentHeight - 400) + 'px';
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.scm.construction.insert.url;
      this.deleteUrl = transactionConfig.sop.scm.construction.delete.url;
      // code setting
      // list setting
    },
    research() {
      this.$emit('getDetail')
    },
    addVendor() {
      this.popupOptions.title = 'LBL0001827'; // 협력업체 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeVendorPopup;
    },
    closeVendorPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.construction.vendors, { vendorCd: item.vendorCd }) === -1) {
            this.construction.vendors.push({
              sopConstructionRelationVendorId: '',  // 공사 참여업체 일련번호
              sopConstructionId: this.popupParam.sopConstructionId,  // 공사 일련번호
              vendorCd: item.vendorCd,  // 참여업체 코드
              vendorName: item.vendorName,  // 참여업체 명
              constructionDetails: '',  // 공사내역
              participationStartDate: '',  // 참여 시작일
              participationEndDate: '',  // 참여 종료일
              period: [],
              regUserId: this.$store.getters.user.userId, // 등록자 ID
              editFlag: 'C',
            })
          }
        })
      }
    },
    deleteVendor() {
      let selectData = this.$refs['vendortable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, item => {
              if (!this.construction.deleteVendors) {
                this.construction.deleteVendors = [];
              }
              if (item.editFlag !== 'C' && this.$_.findIndex(this.construction.deleteVendors, { vendorCd: item.vendorCd }) === -1) {
                this.construction.deleteVendors.push(item)
              }
              this.construction.vendors = this.$_.reject(this.construction.vendors, item);
            });
            this.$refs['vendortable'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    deleteContruction() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopConstructionId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveConstruction() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sop.scm.construction.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sop.scm.construction.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.construction.regUserId = this.$store.getters.user.userId
              this.construction.chgUserId = this.$store.getters.user.userId
              
              this.construction.constructionStartDate = this.construction.period[0]
              this.construction.constructionEndDate = this.construction.period[1]

              if (this.construction.vendors && this.construction.vendors.length > 0) {
                this.$_.forEach(this.construction.vendors, vendor => {
                  if (vendor.period && vendor.period.length === 2 && vendor.period[0]) {
                    this.$set(vendor, 'participationStartDate', vendor.period[0])
                    this.$set(vendor, 'participationEndDate', vendor.period[1])
                  } else {
                    this.$set(vendor, 'participationStartDate', null)
                    this.$set(vendor, 'participationEndDate', null)
                  }
                })
              }
              
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveConstructionCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopConstructionId', _result.data)
      this.$emit('getDetail');
    },
  }
};
</script>